$text-color: #2e2e2e;

$blue: #4285F4;
$red: #DB4437;
$yellow: #F4B400;;
$green: #0F9D58;;

$dark-blue: #285092;
$dark-red: #8C2820;
$dark-yellow: #7E5E03;
$dark-green: #1A542A;

$light-blue: #A1C2FA;
$light-red: #F7B4AE;
$light-yellow: #FDDE82;
$light-green: #9AD4A9;
