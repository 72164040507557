// Box model fix:
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

// Global styles
// body {
//   margin: 0;
// }
